<template>
  <div>

      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="单号,客户编号/名称" allowClear @search="search" />
        </a-col>
        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-range-picker @change="onChangePicker" />
        </a-col> -->

        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_finished_outstock" placeholder="是否完成入库" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="false">未完成</a-select-option>
            <a-select-option :value="true">已完成</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row>
      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="销售单数"><span class="total">{{totalCount}}</span></a-descriptions-item>
        <a-descriptions-item label="销售金额"><span class="total">{{totalAmount}}</span></a-descriptions-item>
      </a-descriptions>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">

          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void" icon="delete"  size="small">作废</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>

  </div>
</template>

<script>
  import { saleOrderList, saleOrdersVoid } from '@/api/sale'

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '销售编号',
            dataIndex: 'number',
            sorter: true,
            width: 120,
          },
          {
            title: '客户',
            dataIndex: 'client_name',
            width: 100,
          },
          {
            title: '销售产品',
            customRender: (value, item, index) => {
              let products = ""
              for (let i in item.sales_goods_items){
                  products = products + item.sales_goods_items[i].goods_name + ','
              }
              return products.substring(0,20)
            },
            width: 160,
          },
          {
            title: '处理日期',
            dataIndex: 'handle_time',
            width: 120
          },
          {
            title: '销售总数量',
            dataIndex: 'total_quantity',
            width: 80
          },
          {
            title: '销售总金额',
            dataIndex: 'total_amount',
            width: 100
          },
          {
            title: '对应出库单',
            dataIndex: 'outstocknumber',
            width: 120
          },
          {
            title: '是否已出库',
            dataIndex: 'is_completed',
            width: 80
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 200
          },
        ],
        searchForm: { page: 1, page_size: 20 },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
        totalCount: 0,
        totalAmount: 0,
      };
    },
    computed: {
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        this.items = [];
        this.searchForm["type"] = 0
        saleOrderList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.totalCount = data.count;
          this.totalAmount = data.total_amount;
          let temp = data.results;
          for (let item in temp){
              temp[item].outstocknumber = temp[item].out_stock_order.number;
              if (temp[item].out_stock_order.is_completed){
                  temp[item].is_completed = '出库完成';
              }else if (temp[item].out_stock_order.remain_quantity < temp[item].out_stock_order.total_quantity){
                  temp[item].is_completed = '部分出库';
              } else{
                  temp[item].is_completed = '未出库';
              }
              this.items.push(temp[item]);
          }
          //this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        this.$router.push({ path: '/sale/sale_record_detail', query: { id: item.id } });
      },

      voidItem(item) {
        saleOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>